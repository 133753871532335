import * as Sentry from '@sentry/browser'

export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig()
    if (!import.meta.dev && !config.public.is_app) {
        Sentry.init({
            dsn: 'https://729caf498460451f818bd38fbbfd60c2@o318353.ingest.us.sentry.io/4505313490960384',
            release: config.public.version,
            sampleRate: 0.1,
            tracesSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        })
    }
})
