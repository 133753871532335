import { default as indexAQJwsb5O6CMeta } from "/opt/buildhome/repo/fe/pages/index.vue?macro=true";
import { default as usersedtAhwEHWBMeta } from "/opt/buildhome/repo/fe/pages/admin/users.vue?macro=true";
import { default as loginml5GplR3unMeta } from "/opt/buildhome/repo/fe/pages/auth/login.vue?macro=true";
import { default as registery22rBiTDSJMeta } from "/opt/buildhome/repo/fe/pages/auth/register.vue?macro=true";
import { default as reset_45password93b6J6I5VdMeta } from "/opt/buildhome/repo/fe/pages/auth/reset-password.vue?macro=true";
import { default as indexZ1MtdhUovwMeta } from "/opt/buildhome/repo/fe/pages/competition/match/index.vue?macro=true";
import { default as events_45locationslSScHth2mFMeta } from "/opt/buildhome/repo/fe/pages/manage/events-locations.vue?macro=true";
import { default as boardsu8fAZadVTnMeta } from "/opt/buildhome/repo/fe/pages/manage/events/[id]/boards.vue?macro=true";
import { default as indexED07axbsqJMeta } from "/opt/buildhome/repo/fe/pages/manage/index.vue?macro=true";
import { default as printer_45settings7Ih7uNqeEQMeta } from "/opt/buildhome/repo/fe/pages/manage/printer-settings.vue?macro=true";
import { default as index3ftTHcmhAxMeta } from "/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/index.vue?macro=true";
import { default as groups08HgzZRGqNMeta } from "/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/play/groups.vue?macro=true";
import { default as losers_45kobIl5PCJSyLMeta } from "/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/play/losers-ko.vue?macro=true";
import { default as results5Zt2chlZodMeta } from "/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/play/results.vue?macro=true";
import { default as winners_45ko4SyMW06uAlMeta } from "/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/play/winners-ko.vue?macro=true";
import { default as playerseKUttN4FZ6Meta } from "/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/players.vue?macro=true";
import { default as _91id_93o3arpRnfzUMeta } from "/opt/buildhome/repo/fe/pages/manage/tournaments/[id].vue?macro=true";
import { default as indexbKeY7LjRr8Meta } from "/opt/buildhome/repo/fe/pages/manage/tournaments/index.vue?macro=true";
import { default as index9BsOYqjZSFMeta } from "/opt/buildhome/repo/fe/pages/play/index.vue?macro=true";
import { default as LobbyPZ1EXvNjM3Meta } from "/opt/buildhome/repo/fe/pages/play/Lobby.vue?macro=true";
import { default as offline6dTcNgCaMfMeta } from "/opt/buildhome/repo/fe/pages/play/offline.vue?macro=true";
import { default as _91id_93Ke86NT0lwkMeta } from "/opt/buildhome/repo/fe/pages/play/online/[id].vue?macro=true";
import { default as privacy_45policymxdJumjfrXMeta } from "/opt/buildhome/repo/fe/pages/privacy-policy.vue?macro=true";
import { default as indexmRLQ7lUIhKMeta } from "/opt/buildhome/repo/fe/pages/profile/index.vue?macro=true";
import { default as my_45matchesZHp7YHrzxXMeta } from "/opt/buildhome/repo/fe/pages/profile/my-matches.vue?macro=true";
import { default as my_45statsfistAK0y4xMeta } from "/opt/buildhome/repo/fe/pages/profile/my-stats.vue?macro=true";
import { default as my_45tournamentsjiRtj17PlVMeta } from "/opt/buildhome/repo/fe/pages/profile/my-tournaments.vue?macro=true";
import { default as settings1nzaWl8mCxMeta } from "/opt/buildhome/repo/fe/pages/profile/settings.vue?macro=true";
import { default as indexwKZyzgUAjAMeta } from "/opt/buildhome/repo/fe/pages/tablet/index.vue?macro=true";
import { default as loginKmG8d4Q2ySMeta } from "/opt/buildhome/repo/fe/pages/tablet/login.vue?macro=true";
import { default as terms_45of_45serviceJucXwa47IfMeta } from "/opt/buildhome/repo/fe/pages/terms-of-service.vue?macro=true";
import { default as bracketsOya8KhgLnyMeta } from "/opt/buildhome/repo/fe/pages/tournaments/[tournament]/brackets.vue?macro=true";
import { default as indexMbmMUy6i7HMeta } from "/opt/buildhome/repo/fe/pages/tournaments/[tournament]/games/[game]/index.vue?macro=true";
import { default as indexWZ6VfcV32nMeta } from "/opt/buildhome/repo/fe/pages/tournaments/[tournament]/games/index.vue?macro=true";
import { default as gamessfxMtyUDfEMeta } from "/opt/buildhome/repo/fe/pages/tournaments/[tournament]/games.vue?macro=true";
import { default as groupsu4zPFnSoyEMeta } from "/opt/buildhome/repo/fe/pages/tournaments/[tournament]/groups.vue?macro=true";
import { default as indexdYEchMwpQ2Meta } from "/opt/buildhome/repo/fe/pages/tournaments/[tournament]/index.vue?macro=true";
import { default as resultsA3csMASxLKMeta } from "/opt/buildhome/repo/fe/pages/tournaments/[tournament]/results.vue?macro=true";
import { default as _91tournament_93gqfnWAPd0SMeta } from "/opt/buildhome/repo/fe/pages/tournaments/[tournament].vue?macro=true";
import { default as indexqKQgOlRqKyMeta } from "/opt/buildhome/repo/fe/pages/tournaments/index.vue?macro=true";
import { default as component_45stub6bb5Y8bvM1Meta } from "/opt/buildhome/repo/fe/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub6bb5Y8bvM1 } from "/opt/buildhome/repo/fe/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/fe/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users___en",
    path: "/en/admin/users",
    meta: usersedtAhwEHWBMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "admin-users___nl",
    path: "/nl/admin/users",
    meta: usersedtAhwEHWBMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "admin-users___de",
    path: "/de/admin/users",
    meta: usersedtAhwEHWBMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___en",
    path: "/en/auth/login",
    meta: loginml5GplR3unMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___nl",
    path: "/nl/auth/login",
    meta: loginml5GplR3unMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-login___de",
    path: "/de/auth/login",
    meta: loginml5GplR3unMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-register___en",
    path: "/en/auth/register",
    meta: registery22rBiTDSJMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-register___nl",
    path: "/nl/auth/register",
    meta: registery22rBiTDSJMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-register___de",
    path: "/de/auth/register",
    meta: registery22rBiTDSJMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password___en",
    path: "/en/auth/reset-password",
    meta: reset_45password93b6J6I5VdMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password___nl",
    path: "/nl/auth/reset-password",
    meta: reset_45password93b6J6I5VdMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password___de",
    path: "/de/auth/reset-password",
    meta: reset_45password93b6J6I5VdMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "competition-match",
    path: "/competition/match",
    meta: indexZ1MtdhUovwMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/competition/match/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/opt/buildhome/repo/fe/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/opt/buildhome/repo/fe/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/opt/buildhome/repo/fe/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "manage-events-locations___en",
    path: "/en/manage/events-locations",
    meta: events_45locationslSScHth2mFMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/events-locations.vue").then(m => m.default || m)
  },
  {
    name: "manage-events-locations___nl",
    path: "/nl/manage/events-locations",
    meta: events_45locationslSScHth2mFMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/events-locations.vue").then(m => m.default || m)
  },
  {
    name: "manage-events-locations___de",
    path: "/de/manage/events-locations",
    meta: events_45locationslSScHth2mFMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/events-locations.vue").then(m => m.default || m)
  },
  {
    name: "manage-events-id-boards___en",
    path: "/en/manage/events/:id()/boards",
    component: () => import("/opt/buildhome/repo/fe/pages/manage/events/[id]/boards.vue").then(m => m.default || m)
  },
  {
    name: "manage-events-id-boards___nl",
    path: "/nl/manage/events/:id()/boards",
    component: () => import("/opt/buildhome/repo/fe/pages/manage/events/[id]/boards.vue").then(m => m.default || m)
  },
  {
    name: "manage-events-id-boards___de",
    path: "/de/manage/events/:id()/boards",
    component: () => import("/opt/buildhome/repo/fe/pages/manage/events/[id]/boards.vue").then(m => m.default || m)
  },
  {
    name: "manage___en",
    path: "/en/manage",
    component: () => import("/opt/buildhome/repo/fe/pages/manage/index.vue").then(m => m.default || m)
  },
  {
    name: "manage___nl",
    path: "/nl/manage",
    component: () => import("/opt/buildhome/repo/fe/pages/manage/index.vue").then(m => m.default || m)
  },
  {
    name: "manage___de",
    path: "/de/manage",
    component: () => import("/opt/buildhome/repo/fe/pages/manage/index.vue").then(m => m.default || m)
  },
  {
    name: "manage-printer-settings___en",
    path: "/en/manage/printer-settings",
    meta: printer_45settings7Ih7uNqeEQMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/printer-settings.vue").then(m => m.default || m)
  },
  {
    name: "manage-printer-settings___nl",
    path: "/nl/manage/printer-settings",
    meta: printer_45settings7Ih7uNqeEQMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/printer-settings.vue").then(m => m.default || m)
  },
  {
    name: "manage-printer-settings___de",
    path: "/de/manage/printer-settings",
    meta: printer_45settings7Ih7uNqeEQMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/printer-settings.vue").then(m => m.default || m)
  },
  {
    name: _91id_93o3arpRnfzUMeta?.name,
    path: "/en/manage/tournaments/:id()",
    meta: _91id_93o3arpRnfzUMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "manage-tournaments-id___en",
    path: "",
    meta: index3ftTHcmhAxMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "manage-tournaments-id-play-groups___en",
    path: "play/groups",
    meta: groups08HgzZRGqNMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/play/groups.vue").then(m => m.default || m)
  },
  {
    name: "manage-tournaments-id-play-losers-ko___en",
    path: "play/losers-ko",
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/play/losers-ko.vue").then(m => m.default || m)
  },
  {
    name: "manage-tournaments-id-play-results___en",
    path: "play/results",
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/play/results.vue").then(m => m.default || m)
  },
  {
    name: "manage-tournaments-id-play-winners-ko___en",
    path: "play/winners-ko",
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/play/winners-ko.vue").then(m => m.default || m)
  },
  {
    name: "manage-tournaments-id-players___en",
    path: "players",
    meta: playerseKUttN4FZ6Meta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/players.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93o3arpRnfzUMeta?.name,
    path: "/nl/manage/tournaments/:id()",
    meta: _91id_93o3arpRnfzUMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "manage-tournaments-id___nl",
    path: "",
    meta: index3ftTHcmhAxMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "manage-tournaments-id-play-groups___nl",
    path: "play/groups",
    meta: groups08HgzZRGqNMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/play/groups.vue").then(m => m.default || m)
  },
  {
    name: "manage-tournaments-id-play-losers-ko___nl",
    path: "play/losers-ko",
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/play/losers-ko.vue").then(m => m.default || m)
  },
  {
    name: "manage-tournaments-id-play-results___nl",
    path: "play/results",
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/play/results.vue").then(m => m.default || m)
  },
  {
    name: "manage-tournaments-id-play-winners-ko___nl",
    path: "play/winners-ko",
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/play/winners-ko.vue").then(m => m.default || m)
  },
  {
    name: "manage-tournaments-id-players___nl",
    path: "players",
    meta: playerseKUttN4FZ6Meta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/players.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93o3arpRnfzUMeta?.name,
    path: "/de/manage/tournaments/:id()",
    meta: _91id_93o3arpRnfzUMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "manage-tournaments-id___de",
    path: "",
    meta: index3ftTHcmhAxMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "manage-tournaments-id-play-groups___de",
    path: "play/groups",
    meta: groups08HgzZRGqNMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/play/groups.vue").then(m => m.default || m)
  },
  {
    name: "manage-tournaments-id-play-losers-ko___de",
    path: "play/losers-ko",
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/play/losers-ko.vue").then(m => m.default || m)
  },
  {
    name: "manage-tournaments-id-play-results___de",
    path: "play/results",
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/play/results.vue").then(m => m.default || m)
  },
  {
    name: "manage-tournaments-id-play-winners-ko___de",
    path: "play/winners-ko",
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/play/winners-ko.vue").then(m => m.default || m)
  },
  {
    name: "manage-tournaments-id-players___de",
    path: "players",
    meta: playerseKUttN4FZ6Meta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/[id]/players.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "manage-tournaments___en",
    path: "/en/manage/tournaments",
    meta: indexbKeY7LjRr8Meta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/index.vue").then(m => m.default || m)
  },
  {
    name: "manage-tournaments___nl",
    path: "/nl/manage/tournaments",
    meta: indexbKeY7LjRr8Meta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/index.vue").then(m => m.default || m)
  },
  {
    name: "manage-tournaments___de",
    path: "/de/manage/tournaments",
    meta: indexbKeY7LjRr8Meta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/manage/tournaments/index.vue").then(m => m.default || m)
  },
  {
    name: "play___en",
    path: "/en/play",
    component: () => import("/opt/buildhome/repo/fe/pages/play/index.vue").then(m => m.default || m)
  },
  {
    name: "play___nl",
    path: "/nl/play",
    component: () => import("/opt/buildhome/repo/fe/pages/play/index.vue").then(m => m.default || m)
  },
  {
    name: "play___de",
    path: "/de/play",
    component: () => import("/opt/buildhome/repo/fe/pages/play/index.vue").then(m => m.default || m)
  },
  {
    name: "play-Lobby___en",
    path: "/en/play/Lobby",
    meta: LobbyPZ1EXvNjM3Meta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/play/Lobby.vue").then(m => m.default || m)
  },
  {
    name: "play-Lobby___nl",
    path: "/nl/play/Lobby",
    meta: LobbyPZ1EXvNjM3Meta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/play/Lobby.vue").then(m => m.default || m)
  },
  {
    name: "play-Lobby___de",
    path: "/de/play/Lobby",
    meta: LobbyPZ1EXvNjM3Meta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/play/Lobby.vue").then(m => m.default || m)
  },
  {
    name: "play-offline___en",
    path: "/en/play/offline",
    meta: offline6dTcNgCaMfMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/play/offline.vue").then(m => m.default || m)
  },
  {
    name: "play-offline___nl",
    path: "/nl/play/offline",
    meta: offline6dTcNgCaMfMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/play/offline.vue").then(m => m.default || m)
  },
  {
    name: "play-offline___de",
    path: "/de/play/offline",
    meta: offline6dTcNgCaMfMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/play/offline.vue").then(m => m.default || m)
  },
  {
    name: "play-online-id___en",
    path: "/en/play/online/:id()",
    meta: _91id_93Ke86NT0lwkMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/play/online/[id].vue").then(m => m.default || m)
  },
  {
    name: "play-online-id___nl",
    path: "/nl/play/online/:id()",
    meta: _91id_93Ke86NT0lwkMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/play/online/[id].vue").then(m => m.default || m)
  },
  {
    name: "play-online-id___de",
    path: "/de/play/online/:id()",
    meta: _91id_93Ke86NT0lwkMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/play/online/[id].vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/opt/buildhome/repo/fe/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___nl",
    path: "/nl/privacy-policy",
    component: () => import("/opt/buildhome/repo/fe/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___de",
    path: "/de/privacy-policy",
    component: () => import("/opt/buildhome/repo/fe/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile___en",
    path: "/en/profile",
    component: () => import("/opt/buildhome/repo/fe/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile___nl",
    path: "/nl/profile",
    component: () => import("/opt/buildhome/repo/fe/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile___de",
    path: "/de/profile",
    component: () => import("/opt/buildhome/repo/fe/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-my-matches___en",
    path: "/en/profile/my-matches",
    meta: my_45matchesZHp7YHrzxXMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/profile/my-matches.vue").then(m => m.default || m)
  },
  {
    name: "profile-my-matches___nl",
    path: "/nl/profile/my-matches",
    meta: my_45matchesZHp7YHrzxXMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/profile/my-matches.vue").then(m => m.default || m)
  },
  {
    name: "profile-my-matches___de",
    path: "/de/profile/my-matches",
    meta: my_45matchesZHp7YHrzxXMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/profile/my-matches.vue").then(m => m.default || m)
  },
  {
    name: "profile-my-stats___en",
    path: "/en/profile/my-stats",
    meta: my_45statsfistAK0y4xMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/profile/my-stats.vue").then(m => m.default || m)
  },
  {
    name: "profile-my-stats___nl",
    path: "/nl/profile/my-stats",
    meta: my_45statsfistAK0y4xMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/profile/my-stats.vue").then(m => m.default || m)
  },
  {
    name: "profile-my-stats___de",
    path: "/de/profile/my-stats",
    meta: my_45statsfistAK0y4xMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/profile/my-stats.vue").then(m => m.default || m)
  },
  {
    name: "profile-my-tournaments___en",
    path: "/en/profile/my-tournaments",
    meta: my_45tournamentsjiRtj17PlVMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/profile/my-tournaments.vue").then(m => m.default || m)
  },
  {
    name: "profile-my-tournaments___nl",
    path: "/nl/profile/my-tournaments",
    meta: my_45tournamentsjiRtj17PlVMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/profile/my-tournaments.vue").then(m => m.default || m)
  },
  {
    name: "profile-my-tournaments___de",
    path: "/de/profile/my-tournaments",
    meta: my_45tournamentsjiRtj17PlVMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/profile/my-tournaments.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings___en",
    path: "/en/profile/settings",
    meta: settings1nzaWl8mCxMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings___nl",
    path: "/nl/profile/settings",
    meta: settings1nzaWl8mCxMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings___de",
    path: "/de/profile/settings",
    meta: settings1nzaWl8mCxMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "tablet",
    path: "/tablet",
    meta: indexwKZyzgUAjAMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/tablet/index.vue").then(m => m.default || m)
  },
  {
    name: "tablet-login",
    path: "/tablet/login",
    meta: loginKmG8d4Q2ySMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/tablet/login.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___en",
    path: "/en/terms-of-service",
    component: () => import("/opt/buildhome/repo/fe/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___nl",
    path: "/nl/terms-of-service",
    component: () => import("/opt/buildhome/repo/fe/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service___de",
    path: "/de/terms-of-service",
    component: () => import("/opt/buildhome/repo/fe/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: _91tournament_93gqfnWAPd0SMeta?.name,
    path: "/en/tournaments/:tournament()",
    meta: _91tournament_93gqfnWAPd0SMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament].vue").then(m => m.default || m),
    children: [
  {
    name: "tournaments-tournament-brackets___en",
    path: "brackets",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/brackets.vue").then(m => m.default || m)
  },
  {
    name: gamessfxMtyUDfEMeta?.name,
    path: "games",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/games.vue").then(m => m.default || m),
    children: [
  {
    name: "tournaments-tournament-games-game___en",
    path: ":game()",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/games/[game]/index.vue").then(m => m.default || m)
  },
  {
    name: "tournaments-tournament-games___en",
    path: "",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/games/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tournaments-tournament-groups___en",
    path: "groups",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/groups.vue").then(m => m.default || m)
  },
  {
    name: "tournaments-tournament___en",
    path: "",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/index.vue").then(m => m.default || m)
  },
  {
    name: "tournaments-tournament-results___en",
    path: "results",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/results.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91tournament_93gqfnWAPd0SMeta?.name,
    path: "/nl/tournaments/:tournament()",
    meta: _91tournament_93gqfnWAPd0SMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament].vue").then(m => m.default || m),
    children: [
  {
    name: "tournaments-tournament-brackets___nl",
    path: "brackets",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/brackets.vue").then(m => m.default || m)
  },
  {
    name: gamessfxMtyUDfEMeta?.name,
    path: "games",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/games.vue").then(m => m.default || m),
    children: [
  {
    name: "tournaments-tournament-games-game___nl",
    path: ":game()",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/games/[game]/index.vue").then(m => m.default || m)
  },
  {
    name: "tournaments-tournament-games___nl",
    path: "",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/games/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tournaments-tournament-groups___nl",
    path: "groups",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/groups.vue").then(m => m.default || m)
  },
  {
    name: "tournaments-tournament___nl",
    path: "",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/index.vue").then(m => m.default || m)
  },
  {
    name: "tournaments-tournament-results___nl",
    path: "results",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/results.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91tournament_93gqfnWAPd0SMeta?.name,
    path: "/de/tournaments/:tournament()",
    meta: _91tournament_93gqfnWAPd0SMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament].vue").then(m => m.default || m),
    children: [
  {
    name: "tournaments-tournament-brackets___de",
    path: "brackets",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/brackets.vue").then(m => m.default || m)
  },
  {
    name: gamessfxMtyUDfEMeta?.name,
    path: "games",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/games.vue").then(m => m.default || m),
    children: [
  {
    name: "tournaments-tournament-games-game___de",
    path: ":game()",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/games/[game]/index.vue").then(m => m.default || m)
  },
  {
    name: "tournaments-tournament-games___de",
    path: "",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/games/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tournaments-tournament-groups___de",
    path: "groups",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/groups.vue").then(m => m.default || m)
  },
  {
    name: "tournaments-tournament___de",
    path: "",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/index.vue").then(m => m.default || m)
  },
  {
    name: "tournaments-tournament-results___de",
    path: "results",
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/[tournament]/results.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tournaments___en",
    path: "/en/tournaments",
    meta: indexqKQgOlRqKyMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/index.vue").then(m => m.default || m)
  },
  {
    name: "tournaments___nl",
    path: "/nl/tournaments",
    meta: indexqKQgOlRqKyMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/index.vue").then(m => m.default || m)
  },
  {
    name: "tournaments___de",
    path: "/de/tournaments",
    meta: indexqKQgOlRqKyMeta || {},
    component: () => import("/opt/buildhome/repo/fe/pages/tournaments/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stub6bb5Y8bvM1Meta?.name,
    path: "/sitemap.xml",
    component: component_45stub6bb5Y8bvM1
  }
]