import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

declare global {
    interface Window {
        Pusher: typeof Pusher;
        Echo: Echo;
    }
}

export default defineNuxtPlugin(() => {
    window.Pusher = Pusher
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        wsHost: import.meta.env.VITE_PUSHER_HOST,
        wsPort: import.meta.env.VITE_PUSHER_PORT,
        wssPort: import.meta.env.VITE_PUSHER_PORT,
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
        forceTLS: false,
        authorizer: (channel: { name: string }) => {
            return {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                authorize: async (socketId: string, callback: (error: any, response?: any) => void) => {
                    try {
                        const response = await useAuthPost('/broadcasting/auth', {
                            method: 'POST',
                            body: {
                                socket_id: socketId,
                                channel_name: channel.name
                            }
                        })

                        callback(null, response)
                    } catch (error) {
                        callback(error)
                    }
                }
            }
        },
    })
})
