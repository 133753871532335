export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appHead = {"link":[{"rel":"icon","href":"/favicon.ico","sizes":"any"},{"rel":"icon","href":"/icon-192x192.png","type":"image/png","sizes":"192x192"},{"rel":"icon","href":"/icon-256x256.png","type":"image/png","sizes":"256x256"},{"rel":"icon","href":"/icon-384x384.png","type":"image/png","sizes":"384x384"},{"rel":"icon","href":"/icon-512x512.png","type":"image/png","sizes":"512x512"}],"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"property":"og:type","content":"website"}],"style":[],"script":[{"hid":"adsbygoogle-script","defer":true,"crossorigin":"anonymous","src":"//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7708034938311608"},{"hid":"adsbygoogle","innerHTML":"if (!window.__abg_called){ (window.adsbygoogle = window.adsbygoogle || []); adsbygoogle.pauseAdRequests=0;\n      adsbygoogle.push({\n        google_ad_client: \"ca-pub-7708034938311608\",\n        overlays: {bottom: false},\n        \n      }); window.__abg_called = true;}"}],"noscript":[],"htmlAttrs":{"lang":"en"}}

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false