import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/fe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/fe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/fe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/opt/buildhome/repo/fe/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/fe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/buildhome/repo/fe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/fe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/fe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/buildhome/repo/fe/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/fe/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/fe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import cookiescript_plugin_Mo4Npveyey from "/opt/buildhome/repo/fe/node_modules/@weareheavy/nuxt-cookie-consent/dist/runtime/plugins/cookiescript.plugin.mjs";
import plugin_sb2LSEEy8f from "/opt/buildhome/repo/fe/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import titles_m9SjywKgWm from "/opt/buildhome/repo/fe/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_SCHuOseEny from "/opt/buildhome/repo/fe/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_XJTWbJLGEl from "/opt/buildhome/repo/fe/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_zOJ1kxPqxh from "/opt/buildhome/repo/fe/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import i18n_yfWm7jX06p from "/opt/buildhome/repo/fe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import laravel_echo_client_xHFhfajl0H from "/opt/buildhome/repo/fe/plugins/laravel-echo.client.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/fe/plugins/sentry.client.ts";
import vue3_touch_events_client_ia14fW7uF5 from "/opt/buildhome/repo/fe/plugins/vue3-touch-events.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  cookiescript_plugin_Mo4Npveyey,
  plugin_sb2LSEEy8f,
  titles_m9SjywKgWm,
  defaults_SCHuOseEny,
  siteConfig_XJTWbJLGEl,
  inferSeoMetaPlugin_zOJ1kxPqxh,
  i18n_yfWm7jX06p,
  laravel_echo_client_xHFhfajl0H,
  sentry_client_shVUlIjFLk,
  vue3_touch_events_client_ia14fW7uF5
]