import lang from "quasar/lang/en-US.js"
import iconSet from "quasar/icon-set/material-icons.js"
import { Dialog,Notify } from "quasar"
import { QCard,QTable } from "quasar"

export const componentsWithDefaults = { QCard,QTable }

export const appConfigKey = "nuxtQuasar"

export const quasarNuxtConfig = {
  lang,
  iconSet,
  components: {"defaults":{"QCard":{"flat":true,"bordered":true},"QTable":{"flat":true}}},
  plugins: {Dialog,Notify},
  config: {"ripple":false,"brand":{"primary":"#5a8fc4","secondary":"#6f8c89","accent":"#aa7bb3","dark":"#2e2e38","dark-page":"#242430","positive":"#6fb07e","negative":"#c4453a","info":"#719ba3","warning":"#b69130"}}
}