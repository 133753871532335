import validate from "/opt/buildhome/repo/fe/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/opt/buildhome/repo/fe/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  anonymous: () => import("/opt/buildhome/repo/fe/middleware/anonymous.ts"),
  auth: () => import("/opt/buildhome/repo/fe/middleware/auth.ts"),
  "board-anonymous": () => import("/opt/buildhome/repo/fe/middleware/board_anonymous.ts"),
  "board-auth": () => import("/opt/buildhome/repo/fe/middleware/board_auth.ts"),
  superadmin: () => import("/opt/buildhome/repo/fe/middleware/superadmin.ts")
}