<script setup lang="ts">
import {CapacitorUpdater} from '@capgo/capacitor-updater'
import {Dialog} from '@capacitor/dialog'
import {Capacitor} from '@capacitor/core'
import {App} from '@capacitor/app';

if (Capacitor.isNativePlatform()) {
  CapacitorUpdater.addListener('updateAvailable', async (res) => {
    try {
      await Dialog.alert({
        title: 'Update Available',
        message: `Version ${res.bundle.version} is available. The app will update now`,
      })
      CapacitorUpdater.set(res.bundle)
    } catch (error) {
      console.log(error)
    }
  })

  CapacitorUpdater.notifyAppReady()
}

const route = useRoute()

const isTablet = route.path.startsWith('/tablet')
const runtimeConfig = useRuntimeConfig()

const links = [
  {rel: 'icon', href: '/favicon.ico'},
]

if (isTablet) {
  links.push({
    rel: 'manifest',
    href: '/scoring-manifest.json'
  })
}

useHead({
  titleTemplate: '%s - DartsSpace | Tournament platform',
  meta: [
    {charset: 'utf-8'},
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no, viewport-fit=cover'
    },
    {key: 'theme-color', name: 'theme-color', content: '#111827'}
  ],
  script: [
    (!isTablet && !import.meta.dev && !runtimeConfig.public.is_app) ? {
      src: 'https://static.cloudflareinsights.com/beacon.min.js',
      'data-cf-beacon': '{"token":"1e963668113048e1a68d004e561e0e16"}',
      defer: true
    } : '',
  ],
  link: links,
  htmlAttrs: {
    lang: 'en'
  }
})

const title = 'DartsSpace'
const description = 'Next-level darts tournament platform'

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true
})

useSeoMeta({
  title,
  description,
  ogTitle: title,
  ogDescription: description,
})

const maximize = ref(false)
const toggleMaximize = () => {
  maximize.value = !maximize.value
}
provide('toggleMaximize', {maximize, toggleMaximize})

defineOgImageComponent('DartsSpace')

onMounted(() => {
  App.addListener('backButton', () => {
    if (window.history.length > 1) {
      window.history.back()
    }
  })
});
</script>

<template>
  <div>
    <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
      <Head>
        <meta name="HandheldFriendly" content="true">

        <Title>{{ title }}</Title>
        <template v-for="link in head.link" :key="link.id">
          <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
        </template>
        <template v-for="meta in head.meta" :key="meta.id">
          <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
        </template>
      </Head>

      <Body>
        <NuxtLoadingIndicator :throttle="0" />

        <NuxtLayout>
          <NuxtPage />
        </NuxtLayout>
      </Body>
    </Html>
  </div>
</template>
