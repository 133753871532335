export const useAuthPost = $fetch.create({
    async onRequest({options}) {
        const route = useRoute()
        const isTablet = route.path.startsWith('/tablet')

        let cookieKey = 'token'
        if (isTablet) {
            cookieKey = 'tablet_token'
        }

        const config = useRuntimeConfig()

        options.baseURL = config.public.baseUrl + '/api/'
        const token = useCookie(cookieKey).value
        if (token === undefined || !token) return

        // Only when there is an impersonate token we use that.
        const impToken = isTablet ? null : useCookie('imp_token').value

        options.headers = new Headers(options.headers)
        options.headers.set('Authorization', `Bearer ${impToken ?? token}`)
    },
})
