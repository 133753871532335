<script setup lang="ts">
import type {NuxtError} from '#app'

useSeoMeta({
  title: 'Page not found',
  description: 'We are sorry but this page could not be found.'
})

defineProps({
  error: {
    type: Object as PropType<NuxtError>,
    required: true
  }
})

useHead({
  htmlAttrs: {
    lang: 'en'
  }
})

const maximize = ref(false)
const toggleMaximize = () => {
  maximize.value = !maximize.value
}
provide('toggleMaximize', {maximize, toggleMaximize})
</script>

<template>
  <NuxtLayout name="default">
    <QPage class="flex flex-center">
      <QCard class="q-pa-md" bordered>
        <QCardSection class="text-center">
          <div class="text-h5 q-mt-md">Oops! Something went wrong</div>
          <div class="text-subtitle1 q-mt-sm">We couldn't find the page you were looking for.</div>
        </QCardSection>

        <QCardActions align="center">
          <QBtn color="primary" label="Go to Home" to="/"/>
        </QCardActions>
      </QCard>
    </QPage>
  </NuxtLayout>
</template>
